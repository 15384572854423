import React from "react"
import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"

import { graphql } from "gatsby"
import TextBlock from "../../components/text-block/TextBlock"
import MarkdownContainer from "../../components/markdown-container/MarkdownContainer"

import styles from "./index.module.css";
import { Head } from "../../components/head/Head"

export default ({ data }) => {
  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Guide to STL" imageSrc={data.portrait.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      >
        <h1>Guide to STL</h1>
      </ParallaxHooklessImage>
      <TextBlock>
        <MarkdownContainer markdownString={data.whereToEat.html} />
        <div className={styles.iframeContainer}>
          <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1kqA-5NG3qjapxSDhTs5soji2wZk2nccr" style={{ height: "100%", width: "100%" }} />
        </div>
      </TextBlock>
      <ParallaxHooklessImage
        src={data.arch.childImageSharp.fluid.src}
        height={data.arch.childImageSharp.fluid.presentationHeight}
        width={data.arch.childImageSharp.fluid.presentationWidth}
      />
      <TextBlock>
        <MarkdownContainer markdownString={data.whatToDo.html} />
      </TextBlock>
      <ParallaxHooklessImage
        src={data.iceCreamOutside.childImageSharp.fluid.src}
        height={data.iceCreamOutside.childImageSharp.fluid.presentationHeight}
        width={data.iceCreamOutside.childImageSharp.fluid.presentationWidth}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  {

    portrait: file(relativePath: { eq: "ice-cream-close-up.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }

    arch: file(relativePath: { eq: "segway-arch.png" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }


    iceCreamOutside: file(relativePath: { eq: "feeding-icecream.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }

    whereToEat: markdownRemark(frontmatter: { title: { eq: "where-to-eat" } }) {
      html
    }
    whatToDo: markdownRemark(frontmatter: { title: { eq: "what-to-do" } }) {
      html
    }
  }
`
